%clearfix {
  content: '';
  display: block;
  height: 1px;
  clear: both;
  float: none;
}

$blue: #3951a2;
$purple: #654792;
$lightpurple: #e8e4f1;
$darkgray: #757575;

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: sans-serif;
  #root,
  .App {
    height: 100%;
    width: 100%;
  }
  .App {
    max-width: 390px;
    padding: 20px;
    margin: 0 auto;
    .loading {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.7);
      color: #fff;
      text-align: center;
      line-height: 100vh;
    }
    > header {
      margin-bottom: 10px;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        > li {
          float: left;
          &:first-of-type {
            width: 50px;
          }
          &:nth-of-type(3) {
            width: 50px;
            display: flex;
            justify-content: right;
            align-items: flex-start;
            flex-wrap: wrap;
            > button {
              color: transparent;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              border: 1px #333 solid;
              background-repeat: no-repeat;
              background-position: 50%;
              &.auto_fill {
                background-color: $purple;
                margin-right: 3px;
                background-image: url('../icons/refresh.svg');
                background-size: 16px;
              }
              &.clear {
                background-image: url('../icons/bin.svg');
                background-color: $darkgray;
                background-size: 11px;
              }
            }
          }
          &.selected_number {
            width: calc(100% - 100px);
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-wrap: wrap;
            > span {
              display: inline-block;
              border: 1px #999 solid;
              height: 20px;
              width: 20px;
              border-radius: 50%;
              font-size: 12px;
              text-align: center;
              line-height: 20px;
              margin-right: 3px;
              background-color: $blue;
              color: #fff;
              &:last-of-type {
                margin-right: 0;
              }
              &.number_not_select_yet {
                background-color: #fff;
                color: #333;
                &.first_element {
                  border-color: $blue;
                }
              }
              &.power_ball_dom {
                background-color: #fff;
                color: #333;
                &.selected {
                  background-color: #8a9ca9;
                  color: #fff;
                }
              }
            }
          }
        }
        &:after {
          @extend %clearfix;
        }
      }
    }
    > main {
      > form {
        section {
          label {
            cursor: pointer;
            display: inline-block;
            float: left;
            width: 10%;
            height: 30px;
            line-height: 30px;
            text-align: center;
            input {
              display: none;
            }
            &.checked {
              background-color: $lightpurple;
              position: relative;
              &:before {
                content: '\00D7';
                display: block;
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                color: purple;
                opacity: 0.5;
                font-size: 40px;
                line-height: 23px;
              }
            }
          }
          &:after {
            @extend %clearfix;
          }
        }
        p {
          background-color: #666;
          color: #fff;
          margin: 0;
          padding: 5px;
          text-align: center;
          font-size: 12px;
        }
      }
      .error_message {
        background-color: red;
        color: #ffffff;
        text-align: center;
        padding: 3px;
        font-size: 10px;
      }
    }
  }
}

* {
  box-sizing: border-box;
}
